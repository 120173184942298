import { Component, useEffect, useState } from "react";
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation, withRouter} from "react-router-dom";
import {OktaAuth} from "@okta/okta-auth-js";
import {LoginCallback, Security, SecureRoute} from "@okta/okta-react";
import { config } from "./config";
import { Redirector } from "./pages/Redirector";
import CommonHeader from "./components/CommonHeader/CommonHeader";
import { DashboardPage } from "./pages/DashboardPage";
import { InvitationPage } from "./pages/InvitationPage";
import { Logout } from "./pages/Logout";
import datadogRum from "./services/datadog-rum";
import { FeatureFlagWrapper } from "./components/FeatureFlagWrapper/FeatureFlagWrapper";
import { useQuery } from "./hooks/useQuery";
import OktaOptionsBuilder from "./utils/oktaOptionsBuilder";
import { SegmentTracking } from "./components/SegmentTracking/SegmentTracking";
import { FEATURE_FLAGS, TRACK_LOGIN_KEY } from "./utils/constants";
import { FeatureFlagRoute } from "./components/FeatureFlagRoute/FeatureFlagRoute";
import { AppDirectoryPage } from "./pages/AppDirectory";

const oktaAuth = new OktaAuth({
    issuer: config.OKTA_ISSUER as string,
    clientId: config.CLIENT_ID,
    redirectUri: window.location.origin + "/callback",
    scopes: config.SCOPE,
    // overwrite Okta's SDK default behavior of writing to localStorage
    storageManager: {
        token: {
            storageTypes: ["sessionStorage"]
        },
        cache: {
            storageTypes: ["sessionStorage"]
        },
        transaction: {
            storageTypes: ["sessionStorage"]
        }
    }
});

function App() {
    const location = useLocation();
    const query = useQuery();
    const [navigationUrl, setNavigationUrl] = useState<string | null>(null);
    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri?: string) => {
        // NOTE: Adding the login/token added event tracking here to session storage
        // as it is the most reliable we can control without navigating away while we obtain the
        // necessary details for the track event.

        window.sessionStorage.setItem(TRACK_LOGIN_KEY, "true");
        const uri = originalUri || window.location.origin;
        window.location.replace(uri.endsWith("/") ? uri : `${uri}/`);
    };

    function onAuthRequired(_oktaAuth: OktaAuth) {
        const navigationUrl = query.get('navigationUrl');
        if (navigationUrl) {
            setNavigationUrl(navigationUrl);
        }
        const options = new OktaOptionsBuilder().buildOktaOptions(query);
        oktaAuth.signInWithRedirect(options);
    }

    useEffect(() => {
        const viewName = (location.pathname.endsWith("/") ?
            location.pathname.slice(0, -1) :
            location.pathname).split("/").pop();
        datadogRum.startView({
            name: viewName || "dashboard"
        });
    }, [location.pathname]);

    return (
        <Router>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired} >
                <FeatureFlagWrapper>
                    {!navigationUrl && <SegmentTracking />}
                    <Switch>
                        <Route path="/accept-invitation" render={() => (
                            <>
                                <CommonHeader />
                                <InvitationPage />
                            </>
                        )} />
                        <Route path="/logout" render={() => (
                            <>
                                <CommonHeader />
                                <Logout />
                            </>
                        )} />
                        <Route path="/callback" component={LoginCallback} />
                        <SecureRoute path="/" exact render={() => {
                            return (
                                <>
                                    {!navigationUrl && <CommonHeader navigateOnOrgChange />}
                                    <Redirector navigationUrl={navigationUrl}/>
                                </>
                            );
                        }}
                        />
                        <SecureRoute path="/:organizationId">
                            <>
                                <CommonHeader />
                                <Switch>
                                    <Redirect path="/dashboard" exact to="/" />
                                    <SecureRoute path="/:organizationId/dashboard" exact component={DashboardPage} />
                                    <Route path="/:organizationId/global-app-directory"
                                           render={() =>
                                               <FeatureFlagRoute
                                                   featureFlag={FEATURE_FLAGS.ENABLE_APP_DIRECTORY}
                                                   onElement={<AppDirectoryPage />}
                                                   offElement={(
                                                       <>
                                                           <opti-error-404></opti-error-404>
                                                       </>
                                                   )}
                                               />
                                           }
                                    />
                                    <Route path="*" render={() => (
                                        <>
                                            <opti-error-404></opti-error-404>
                                        </>
                                    )} />
                                </Switch>
                            </>
                        </SecureRoute>
                        <Route path="*" render={() => (
                            <>
                                <CommonHeader navigateOnOrgChange />
                                <opti-error-404></opti-error-404>
                            </>
                        )} />
                    </Switch>
                </FeatureFlagWrapper>
            </Security>
        </Router>
    );
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
    render() {
        return (
            <Router>
                <AppWithRouterAccess />
            </Router>
        );
    }
}

export default RouterApp;