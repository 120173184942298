import { Organization } from "@/types/Organization.type";
import { COMMON_PLATFORM, HOME_DASHBOARD } from "../utils/constants";

export function emitSegmentPageEvent({
    organization,
    page
}: {
    organization: Organization;
    page: string
}) {
    try {
        window.analytics?.page(page, {
            groupId: organization.CrmId,
            subProductName: HOME_DASHBOARD
        });
    } catch (e: any) {
        console.warn(`Error logging Segment page event: ${e.message}`);
    }
}

export function emitSegmentIdentifyEvent({
    organization,
    email,
    name
}: {
    organization: Organization;
    email: string;
    name: string
}) {
    try {
        window.analytics?.identify(email, {
            groupId: organization.CrmId,
            instanceName: organization.Name,
            isOptiUser: isOptiUser(email),
            name,
            organizationId: organization.Id,
            userId: email,
        });
    } catch (e: any) {
        console.warn(`Error logging Segment identify event: ${e.message}`);
    }
}

export function emitSegmentGroupEvent(organization: Organization) {
    try {
        window.analytics?.group(organization.CrmId, {
            groupId: organization.CrmId,
            hasTurnstile: true,
            name: organization.Name,
            organizationId: organization.Id,
        });
    } catch (e: any) {
        console.warn(`Error logging Segment group event: ${e.message}`);
    }
}

export function emitSegmentTrackEvent({
    customerId,
    data,
    email,
    eventName
}: {
    customerId: string;
    data: any
    email: string;
    eventName: string;
}) {
    try {
        window.analytics?.track(eventName, {
            ...data,
            isOptiUser: isOptiUser(email),
            groupId: customerId,
            productName: COMMON_PLATFORM,
            subProductName: HOME_DASHBOARD,
            userId: email,
        });
    } catch (e: any) {
        console.warn(`Error logging Segment track event: ${e.message}`);
    }
}

function isOptiUser(email: string) {
    const domainPattern = /@(optimizely\.com|episerver\.net|episerver\.com)$/i;
    return domainPattern.test(email);
}
