import { useDecision } from "@optimizely/react-sdk";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

type FeatureFlagRouteProps = {
    featureFlag: string;
    onElement: JSX.Element;
    offElement: JSX.Element;
};

export const FeatureFlagRoute = ({ featureFlag, onElement, offElement }: FeatureFlagRouteProps) => {
    const [flagDecision, clientReady] = useDecision(featureFlag);

    const isFeatureEnabled = flagDecision?.enabled;

    if (!clientReady) {
        return <LoadingIndicator />
    } else if (isFeatureEnabled) {
        return onElement;
    } else {
        return offElement;
    }
};

FeatureFlagRoute.displayName = "FeatureFlagRoute";
