"use client";
import {useOktaCredentials} from "../../hooks/useOktaCredentials";
import {withOktaAuth} from "@okta/okta-react";
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
// @ts-ignore-next-line
import {brandTextPrimaryColor} from "@optimizely/design-tokens/dist/js/colors";
import {FC, useCallback, useEffect, useRef} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {GLOBAL_BAR_ORG_CHANGE_EVENT_NAME} from "../../utils/constants";

type CommonHeaderProps = {
    navigateOnOrgChange?: boolean;
}
const CommonHeader: FC<CommonHeaderProps & IOktaContext> = ({navigateOnOrgChange}: CommonHeaderProps) => {
    const {organizationId} = useParams<{ organizationId?: string }>();

    const history = useHistory();
    const location = useLocation();

    const isAppDirectory = location.pathname.endsWith("global-app-directory");

    const {accessToken, email} = useOktaCredentials();

    const mounted = useRef(false);
    const receivedFirstEvent = useRef(false);
    const onLogout = useCallback(async () => {
        history.push("/logout");
    }, [history]);

    const onHeaderReady = useCallback(() => {
        if (organizationId && !mounted.current) {
            const orgChangeEvent = new CustomEvent(GLOBAL_BAR_ORG_CHANGE_EVENT_NAME, {
                detail: {
                    type: "organization",
                    value: {organizationId}
                }
            });
            mounted.current = true;
            window.dispatchEvent(orgChangeEvent);
        }
    }, [organizationId]);

    const onHeaderChange = useCallback((change: any) => {
        const {organization, product, instance} = change?.detail || {};
        const orgId = organization?.value;
        if (orgId && (navigateOnOrgChange || (mounted.current && orgId !== organizationId))) {
            history.push(`/${orgId}/dashboard`);
        } else if (orgId && !instance) {
            if (product.value === "App Directory") {
                history.push(`/${orgId}/global-app-directory`);
            }
            if (product.value === "Home") {
                history.push(`/${orgId}/dashboard`);
            }

            receivedFirstEvent.current = true;
        }

        const {valueObject} = instance || {};
        const {NavigationUrl} = valueObject || {};
        if (NavigationUrl) { //has instance and Navigation URL
            const separator = NavigationUrl.includes("?") ? "&" : "?";
            window.location.href = NavigationUrl !== 'https://sa2.commerce.opti-demo.online/admin' ? `${NavigationUrl}${separator}login_hint=${encodeURIComponent(email)}` : NavigationUrl
        }
    }, [email, navigateOnOrgChange, history, organizationId]);

    useEffect(() => {
        const headerElement = document.getElementsByTagName('opti-common-header')[0];
        if (headerElement) {
            headerElement.addEventListener('@opti-common-header:logout', onLogout, {capture: true});
            headerElement.addEventListener('@opti-common-header:ready', onHeaderReady, {once: true});
            headerElement.addEventListener('@opti-common-header:change', onHeaderChange, {capture: true});
        }

        return () => {
            if (headerElement) {
                headerElement.removeEventListener('@opti-common-header:logout', onLogout);
                headerElement.removeEventListener('@opti-common-header:ready', onHeaderReady);
                headerElement.removeEventListener('@opti-common-header:change', onHeaderChange);
            }
        };
    }, [accessToken, onLogout, onHeaderReady, onHeaderChange]);

    return (
        <>
            <div style={{height: '40px', backgroundColor: `${brandTextPrimaryColor}`}} className="">
                <opti-common-header auth-token={accessToken}
                                    disable-navigation="true"
                                    sticky
                                    instance-id={isAppDirectory ? "App Directory": "Home"}
                                    organization-id={organizationId}
                                    product-name={isAppDirectory ? "App Directory" : "Home"}
                ></opti-common-header>
            </div>
        </>
    )
};

export default withOktaAuth(CommonHeader);