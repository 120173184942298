export const REDIRECT_URI_KEY = "@optimizely/home:okta-redirect-uri";

export const APP_DIRECTORY_EVENT_NAMES: { [key:string]:  keyof ElementEventMap } = {
    APP_SELECTED: "@opti-app-directory:app-selected" as keyof ElementEventMap,
    FILTERS: "@opti-app-directory:filters" as keyof ElementEventMap,
    PAGINATION: "@opti-app-directory:pagination" as keyof ElementEventMap,
    READY: "@opti-app-directory:ready" as keyof ElementEventMap,
    SEARCH: "@opti-app-directory:search" as keyof ElementEventMap
};

export const FEATURE_FLAGS = {
    ENABLE_APP_DIRECTORY: "enable_app_directory"
};

export const INVALID_NAVIGATION_URL = "Invalid NavigationUrl. Contact your admin for further assistance.";

export const HOME_DASHBOARD = "Home Dashboard";

export const COMMON_PLATFORM = "Common Platform";

export const NON_ORG_ID_PATHS = [
    "accept-invitation",
    "logout",
    "callback"
];

export const GLOBAL_BAR_ORG_CHANGE_EVENT_NAME = "@opti-product-switcher:set";
export const SEGMENT_PRODUCT_NAVIGATION_EVENT_NAME = "@home:product-selected";
export const SEGMENT_SUPPORT_NAVIGATION_EVENT_NAME = "@home:help-and-resource-link-navigation";

export const SEGMENT_TRACK_EVENT_PRODUCT_NAVIGATION = "Product Navigation";
export const SEGMENT_TRACK_EVENT_SUPPORT_NAVIGATION = "Support Navigation";
export const SEGMENT_TRACK_EVENT_TOKEN_ADDED = "Authentication: Token Added";
export const SEGMENT_TRACK_EVENT_TOKEN_RENEWED = "Authentication: Token Renewed";

export const TRACK_LOGIN_KEY = "TRACK_LOGIN";

export const SEGMENT_COMPONENTS = {
    APP_DIRECTORY: "Global App Directory"
}

export const SEGMENT_EVENTS = {
    APP_DIRECTORY_APP_SELECTED: `${SEGMENT_COMPONENTS.APP_DIRECTORY}: Selected App`,
    APP_DIRECTORY_BACK_TO_SEARCH: `${SEGMENT_COMPONENTS.APP_DIRECTORY}: Back to Search (App Unselected)`,
    APP_DIRECTORY_FILTERS_CHANGED: `${SEGMENT_COMPONENTS.APP_DIRECTORY}: Update Filters`,
    APP_DIRECTORY_LOADED: `${SEGMENT_COMPONENTS.APP_DIRECTORY}: Loaded`,
    APP_DIRECTORY_SEARCH: `${SEGMENT_COMPONENTS.APP_DIRECTORY}: Update Search`
}


