import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useRef } from "react";
import { APP_DIRECTORY_EVENT_NAMES, SEGMENT_COMPONENTS, SEGMENT_EVENTS } from "../utils/constants";
import { emitSegmentTrackEvent } from "../services/segment";
import { useOrganization } from "../hooks/useOrganization";
import { useOktaCredentials } from "../hooks/useOktaCredentials";

function useSearchParams() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const AppDirectoryPage = () => {
    const queryParams = useSearchParams();
    const history = useHistory();
    const location = useLocation();
    const { organizationId } = useParams() as { organizationId: string };
    const { accessToken, email } = useOktaCredentials();
    const { organization } = useOrganization({ accessToken, organizationId });
    const readyStates = useRef({
        crmReady: false,
        appDirectoryReady: false,
        alreadyEmitted: false
    });
    const lastFired = useRef<any>({
        app: null,
        search: null,
        filters: null,
        pagination: null
    });

    const handleReplaceQueryParam = useCallback(({ param, value }: { param: string,  value: string | number }) => {
        const newSearchParams = queryParams;

        const currentValue = queryParams.get(param);
        if (currentValue !== value) {
            if (!value) {
                newSearchParams.delete(param);
            } else {
                newSearchParams.set(param, `${value}`);
            }
            history.push({ search: newSearchParams.toString(), pathname: location.pathname });
        }
    }, [history, location.pathname, queryParams]);

    const trackReadyEvent = useCallback(() => {
        if (!readyStates.current.alreadyEmitted) {
            emitSegmentTrackEvent({
                eventName: SEGMENT_EVENTS.APP_DIRECTORY_LOADED,
                email,
                customerId: organization!.CrmId,
                data: {
                    component: SEGMENT_COMPONENTS.APP_DIRECTORY,
                    appliedFilters: {
                        categories: queryParams.get("categories"),
                        prices: queryParams.get("prices"),
                        products: queryParams.get("products"),
                    },
                    pagination: { page: queryParams.get("page") },
                    searchQuery: queryParams.get("q"),
                    appName: queryParams.get("app")
                },
            });
            readyStates.current.alreadyEmitted = true;
        }
    }, [email, organization, queryParams])

    useEffect(() => {
        if (organization?.CrmId && readyStates.current.appDirectoryReady) {
            trackReadyEvent();
        } else if (organization?.CrmId) {
            readyStates.current.crmReady = true;
        }
    }, [organization, trackReadyEvent]);


    const onReady = useCallback(() => {
        if (!organization?.CrmId) {
            readyStates.current.appDirectoryReady = true;
            return;
        }
        trackReadyEvent();
    }, [organization?.CrmId, trackReadyEvent]);

    const paginationEventHandler = useCallback((event: Event) => {
        const { detail } = event as CustomEvent;
        const { page } = detail || {};
        handleReplaceQueryParam({ param: "page", value: page });
    }, [handleReplaceQueryParam]);

    const onSearchChange = useCallback((event: Event) => {
        const { detail: searchValue } = event as CustomEvent;
        handleReplaceQueryParam({ param: "q", value: searchValue });
        if (!organization?.CrmId || (lastFired.current?.search === searchValue)) return;
        emitSegmentTrackEvent({
            eventName: SEGMENT_EVENTS.APP_DIRECTORY_SEARCH,
            email,
            customerId: organization!.CrmId,
            data: {
                component: SEGMENT_COMPONENTS.APP_DIRECTORY,
                searchQuery: searchValue
            }
        });
        lastFired.current = { type: "search", value: searchValue };
    }, [email, handleReplaceQueryParam, organization]);

    const onFilterChange = useCallback((event: Event) => {
        const { detail } = event as CustomEvent;
        const { categoryIds = [], productIds = [], priceIds = [] } = detail || {};
        handleReplaceQueryParam({ param: "categories", value: categoryIds.join(",") });
        handleReplaceQueryParam({ param: "products", value: productIds.join(",") });
        handleReplaceQueryParam({ param: "prices", value: priceIds.join(",") });
        if (!organization?.CrmId || (lastFired.current?.filters === detail)) return;
        emitSegmentTrackEvent({
            eventName: SEGMENT_EVENTS.APP_DIRECTORY_FILTERS_CHANGED,
            email,
            customerId: organization!.CrmId,
            data: {
                component: SEGMENT_COMPONENTS.APP_DIRECTORY,
                appliedFilters: {
                    categories: queryParams.get("categories"),
                    prices: queryParams.get("prices"),
                    products: queryParams.get("products"),
                }
            }
        });
        lastFired.current.filters = detail;
    }, [email, handleReplaceQueryParam, organization, queryParams]);

    const onAppChange = useCallback((event: Event) => {
        const { detail } = event as CustomEvent;
        const { name, route } = detail || {};
        handleReplaceQueryParam({ param: "app", value: route });
        if (!organization?.CrmId || lastFired.current.app === name) return;
        emitSegmentTrackEvent({
            eventName: name ? SEGMENT_EVENTS.APP_DIRECTORY_APP_SELECTED : SEGMENT_EVENTS.APP_DIRECTORY_BACK_TO_SEARCH,
            email,
            customerId: organization!.CrmId,
            data: {
                component: SEGMENT_COMPONENTS.APP_DIRECTORY,
                selectedApp: name || null,
            }
        });
        lastFired.current.app = name;
    }, [email, handleReplaceQueryParam, organization]);

    useEffect(() => {
        const appDirectoryEl = document.getElementsByTagName('opti-app-directory')[0];
        if (!appDirectoryEl) return;

        appDirectoryEl.addEventListener(APP_DIRECTORY_EVENT_NAMES.APP_SELECTED, onAppChange, { capture: true });
        appDirectoryEl.addEventListener(APP_DIRECTORY_EVENT_NAMES.FILTERS, onFilterChange, { capture: true });
        appDirectoryEl.addEventListener(APP_DIRECTORY_EVENT_NAMES.PAGINATION, paginationEventHandler, { capture: true });
        appDirectoryEl.addEventListener(APP_DIRECTORY_EVENT_NAMES.READY, onReady, { capture: true });
        appDirectoryEl.addEventListener(APP_DIRECTORY_EVENT_NAMES.SEARCH, onSearchChange, { capture: true });

        return () => {
            appDirectoryEl.removeEventListener(APP_DIRECTORY_EVENT_NAMES.APP_SELECTED, onAppChange);
            appDirectoryEl.removeEventListener(APP_DIRECTORY_EVENT_NAMES.FILTERS, onFilterChange);
            appDirectoryEl.removeEventListener(APP_DIRECTORY_EVENT_NAMES.PAGINATION, paginationEventHandler);
            appDirectoryEl.removeEventListener(APP_DIRECTORY_EVENT_NAMES.READY, onReady);
            appDirectoryEl.removeEventListener(APP_DIRECTORY_EVENT_NAMES.SEARCH, onSearchChange);

        }
    }, [onAppChange, onFilterChange, onReady, onSearchChange, paginationEventHandler]);

    const filters = {
        categoryIds: queryParams.get("categories")?.split(",") || [],
        productIds: queryParams.get("products")?.split(",") || [],
        priceIds: queryParams.get("prices")?.split(",") || []
    };

    return (
        <div style={{ minHeight: 'calc(100vh - 40px)', backgroundColor: "#F9FAFC" }}>
            <opti-app-directory app-route={queryParams.get("app")} search-query={queryParams.get("q")} filters={JSON.stringify(filters)} pagination={JSON.stringify({ page: +(queryParams.get("page") || 1) })}>Waiting for App Directory...</opti-app-directory>
        </div>
    )
};

AppDirectoryPage.displayName = "AppDirectoryPage";