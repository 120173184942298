import { useInstancePermissions } from "../hooks/useInstancePermissions";
import { useParams } from "react-router-dom";
import { getInstancesByProducts } from "../utils/instance-helpers";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { DashboardLayout } from "../components/DashboardLayout/DashboardLayout";
import { ProductAccessDashboard } from "../components/ProductAccessDashboard/ProductAccessDashboard";
import { EmptyDashboard } from "../components/EmptyDashboard/EmptyDashboard";
import { useOktaCredentials } from "../hooks/useOktaCredentials";

export const DashboardPage = () => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const { accessToken, email, name } = useOktaCredentials();

    const { instancePermissions, loading, error } = useInstancePermissions({ email, accessToken });

    if (loading) {
        return <LoadingIndicator />
    }

    if (error) {
        return <div>An error occurred: {error}</div>
    }

    const organizationInstances = instancePermissions?.filter((ip: any) => {
        return ip.OrganizationId === organizationId;
    });
    const instancesByProducts = getInstancesByProducts({ instances: organizationInstances });

    const [userFirstName] = name?.split(" ") || [];
    const productNames = Object.keys(instancesByProducts || {});
    const accessDashboard = !productNames.length ? <EmptyDashboard name={userFirstName} /> : <ProductAccessDashboard name={userFirstName} email={email} products={instancesByProducts} />;

    return (
        <DashboardLayout>{accessDashboard}</DashboardLayout>
    );
}

DashboardPage.displayName = 'DashboardPage';