import { useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";

export const useOktaCredentials = () => {
    const { authState } = useOktaAuth();
    const { accessToken: token, idToken } = authState || {};
    const { accessToken } = token || {};
    const { email = "", name = "" } = idToken?.claims || {};

    const credentials = useMemo(() => {
        return { accessToken, email, name };
    }, [accessToken, email, name]);

    return credentials;
};
