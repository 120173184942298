import { useQuery } from "../hooks/useQuery";
import { useHistory } from "react-router-dom";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { useEffect } from "react";
import { useOktaCredentials } from "../hooks/useOktaCredentials";

/**
 * Handles the redirect after the authentication.
 * @constructor
 */
interface NavigationRedirectorProps {
    navigationUrl: string | null;
}
export const Redirector = ({ navigationUrl }: NavigationRedirectorProps) => {
    const query = useQuery();
    const history = useHistory();
    const orgId = query.get('orgId');
    const { email } = useOktaCredentials();

    useEffect(() => {
        if (navigationUrl) {
            const url = new URL(navigationUrl);
            if (email) {
                url.searchParams.set('login_hint', email);
            }
            window.location.href = url.toString();
        } else if (orgId) {
            history.push(`/${orgId.replace('/', '')}/dashboard`);
        }
    }, [history, email, navigationUrl, orgId]);

    return (<LoadingIndicator />)
}