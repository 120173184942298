import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { config } from "../../config";
import { useCallback, useEffect, useRef, useState } from "react";
import { Profile } from "../../types/Profile.type";
import { useLocation } from "react-router-dom";
import { useOktaCredentials } from "../../hooks/useOktaCredentials";
import { NON_ORG_ID_PATHS } from "../../utils/constants";
import { Button, Spinner, Typography } from "@optimizely/axiom";
import CommonHeader from "../CommonHeader/CommonHeader";
import { datadogRum } from "@datadog/browser-rum";

const optimizelyClient = createInstance({ sdkKey: config.OPTIMIZELY_SDK_KEY });

function isClientValid() {
    return optimizelyClient.getOptimizelyConfig() !== null;
}

export const FeatureFlagWrapper = ({ children }: { children: React.ReactNode }) => {
    const [isClientReady, setIsClientReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<Profile | null>(null);
    const [failedGettingProfile, setFailedGettingProfile] = useState(false);
    const [retryCount, setRetryCount] = useState(0);

    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [_, organizationId = ""] = location.pathname.split("/");
    if (NON_ORG_ID_PATHS.includes(organizationId)) {
        organizationId = "";
    }

    const { accessToken, email } = useOktaCredentials();
    const fetchedRef = useRef<Profile | null>(null);
    const optimizelyReadyRef = useRef(false);

    const getProfile = useCallback(() => {
        if (!email || !accessToken) return;
        setLoading(true);

        fetch(`${config.IDX_SERVICE_URL}/api/users/${email.toLowerCase()}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setFailedGettingProfile(false);
                fetchedRef.current = res;
                setProfile(res);
            })
            .catch((error) => {
                datadogRum.addError(error, { component:"FeatureFlagWrapper", action: "getProfile", retryAttempt: retryCount + 1 });
                setFailedGettingProfile(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [accessToken, email, retryCount]);

    useEffect(() => {
        if (!optimizelyReadyRef.current) {
            optimizelyClient.onReady().then(() => {
                if (isClientValid()) {
                    setIsClientReady(true);
                    optimizelyReadyRef.current = true;
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!profile && accessToken && email && isClientReady) {
            getProfile();
        }
    }, [accessToken, email, isClientReady, profile, getProfile, retryCount]);

    const retry = () => {
        setFailedGettingProfile(false);
        setRetryCount((prev) => prev + 1);
    };

    if (failedGettingProfile) {
        return (
            <div>
                <CommonHeader />
                <div className="flex flex-column justify-content-center align-items-center push-quad--top">
                    <Typography type="header3" className="flex flex-column justify-content-center align-items-center">
                        <p>We were unable to retrieve your account. </p>
                        <p>
                            If you feel this is an error, please{" "}
                            <a
                                rel="noreferrer"
                                href="https://support.optimizely.com/hc/en-us/requests/new"
                                target="_blank"
                            >
                                submit a support ticket.
                            </a>
                        </p>
                    </Typography>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <Button onClick={retry} style="highlight" className="push-quad--top" isDisabled={loading}>
                        {loading && <Spinner size="small" />} Try Again
                    </Button>
                </div>
            </div>
        );
    }

    if (!email) {
        return children;
    }

    return (
        <OptimizelyProvider
            optimizely={optimizelyClient}
            user={{
                id: profile ? profile.Id : email,
                attributes: {
                    email: email.toLowerCase(),
                    homeOrganizationId: profile ? profile.HomeOrganizationId : "",
                    organizationId,
                },
            }}
        >
            {children}
        </OptimizelyProvider>
    );
};
