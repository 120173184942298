import { config } from "../config";
import { datadogRum } from "@datadog/browser-rum";

if (config.DATADOG_APP_ID) {
    try {
        datadogRum.init({
            applicationId: config.DATADOG_APP_ID,
            clientToken: config.DATADOG_CLIENT_TOKEN!,
            site: "us3.datadoghq.com",
            service: "home-app",
            env: process.env.REACT_APP_ENV || "local",
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
        });

        datadogRum.startSessionReplayRecording();
    } catch (e: any) {
        console.error(`Error initializing Datadog: ${e.message}`);
    }
}

export default datadogRum;
